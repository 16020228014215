@import "_generated-font-mixins.scss";
.wrapper {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 1000px;
  max-width: 100%;
  margin: 4px 0 6px;

  &.mobile {
    overflow-x: auto;
    padding-right: 8px;
  }
}
