@import "_generated-font-mixins.scss";
.nav {
  &__card {
    cursor: pointer;

    position: relative;

    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    min-width: 192px;
    max-width: 320px;
    min-height: 104px;
    margin: 4px 0;
    margin-bottom: 10px;

    text-decoration: none;
    list-style-type: none;

    border-radius: 5px;

    &:nth-child(1) {
      margin-left: 0;
    }

    & + .nav__card {
      margin-left: 8px;
    }
  }

  &__title {
    position: relative;
    z-index: 2;

    padding: 10px 10px 0;

    font-size: 14px;
    font-weight: 400;
    line-height: 17.85px;
    color: #000;
  }

  &__title_project {
    position: absolute;
    bottom: 5px;
    left: 10px;

    font-size: 9px;
    font-weight: 400;
    line-height: 11.47px;
    color: #005cc3;
  }

  &__icon {
    align-self: flex-end;
    width: 65px;
    height: 65px;
  }
}
