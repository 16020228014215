@import "_generated-font-mixins.scss";
.categories {
  display: flex;
  height: 100%;
  margin-top: 8px;

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    box-sizing: border-box;

    font-size: 15px;
    line-height: 16px;

    &__item {
      & > a {
        cursor: pointer;

        display: flex;

        font-size: 15px;
        line-height: 16px;
        color: #005cc3;

        &:hover {
          color: #c01;
        }
      }
    }
  }
}
